<script>
import { Bar } from 'vue-chartjs'
const dashModule = () => import('./../../../services/dashboard')

export default {
  extends: Bar,
  name: 'RankingPlano',
  data () {
    return {
      chartdata: {
        labels: [],
        datasets: [
          {
            label: 'Quantidade vendida',
            backgroundColor: '#006D7C',
            data: [],
            borderWidth: 1
          },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  },
  mounted () {
    // this.renderChart(this.chartdata, this.options)
    this.getPlanos()
  },
  methods: {
    async getPlanos () {
      try {
        let dashService = await dashModule()
        let resp = await dashService.rankingPlanos(this.$axios)
        if (resp.status === 200 && resp.data) {
          for (let plano of resp.data) {
            this.chartdata.labels.push(plano.descricao)
            this.chartdata.datasets[0].data.push(plano.qtd)
          }
          this.renderChart(this.chartdata, this.options)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>